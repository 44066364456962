const initialState = {
  adminVouchers: [],
  adminTables: [],
  bounty: []
};

export default function vouchersReducer(state = initialState, action) {
  switch (action.type) {
    case 'INIT_ADMIN_VOUCHERS':
      return {
        ...state,
        adminVouchers: action.payload,
      };
    case 'ADD_NEW_ADMIN_VOUCHER':
      let tempAdd = [...state.adminVouchers];
      tempAdd.push(action.payload);
      return {
        ...state,
        adminVouchers: tempAdd,
      };
    case 'INIT_ADMIN_TABLES':
      return {
        ...state,
        adminTables: action.payload
      }
    case 'INIT_ADMIN_BOUNTY':
      return {
        ...state,
        bounty: action.payload
      }
    case 'LOG_OUT':
      return {
        adminVouchers: [],
        adminTables: [],
        bounty: []
      };
    default:
      return state;
  }
}
