const initialState = {
  loading: false,
  list: {
    result: [],
    __metadata: {},
  },
};

export default function blogsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_BLOGS_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'INIT_BLOGS':
      return {
        ...state,
        list: action.payload,
      };
    case 'ADD_BLOG':
      const add_blogs = { ...state.list };
      add_blogs.result = [action.payload, ...state.list.result];
      return {
        ...state,
        list: add_blogs,
      };
    case 'UPDATE_BLOG':
      const update_blogs = { ...state.list };
      const update_blogs_index = update_blogs.result.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_blogs_index > -1) {
        update_blogs.result[update_blogs_index] = action.payload;
      }
      return {
        ...state,
        list: update_blogs,
      };
    case 'RESET_BLOGS':
      return {
        list: {
          result: [],
          __metadata: {},
        },
      }
    default:
      return state;
  }
}
