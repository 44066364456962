
const initialState = {
  feedback: []
};

export default function feedbackReducers(state = initialState, action) {
  switch (action.type) {
    case 'GET_FEEDBACK':
      return {
        ...state,
        feedback: action.payload,
      }
    default:
      return state;
  }
}
