import * as actionTypes from '../../actionTypes';

const initialState = {
  deliveryList: [],
  deliveryError: {},
  deliveryPending: false,
  deliveryView: 'date',
};

export default function employeeReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.DELIVERY_DATA_LOADING:
      return {
        ...state,
        deliveryPending: true,
      };
    case actionTypes.DELIVERY_DATA_SUCCESS:
      return {
        ...state,
        deliveryList: action.payload,
        deliveryPending: false,
      };
    case actionTypes.DELIVERY_DATA_FAILED:
      return {
        ...state,
        deliveryError: action.payload,
        deliveryPending: false,
      };
    case actionTypes.DELIVERY_VIEW:
      return {
        ...state,
        deliveryView: action.payload,
      };
    default:
      return state;
  }
}
