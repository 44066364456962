import * as actionTypes from '../../actionTypes';

const initialState = {
  bannerLoading: false,
  bannerList: []
};

export default function bannerReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.BANNER_LOADING:
      return {
        ...state,
        bannerLoading: action.payload,
      };
    case actionTypes.INIT_BANNER:
      return {
        ...state,
        bannerList: action.payload,
      };
    case actionTypes.ADD_TO_BANNER:
      let addBanner = [...state.bannerList];
      addBanner.push(action.payload)
      return {
        ...state,
        bannerList: addBanner,
      };
    case actionTypes.REMOVE_FROM_BANNER:
      let removeBanner = [...state.bannerList];
      let removeBannerIndex = removeBanner.findIndex((a) => a._id === action.payload);
      if(removeBannerIndex > -1){
        removeBanner.splice(removeBannerIndex, 1)
      }
      return {
        ...state,
        bannerList: removeBanner,
      };
    default:
      return state;
  }
}
