import React, {useState} from 'react';
import {Button, Card, Col, Input, Modal, Row, Typography} from 'antd';
import Axios from 'axios';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Divider from '@material-ui/core/Divider';
import {UploadOutlined} from '@ant-design/icons';

const {Title} = Typography;
const {TextArea} = Input;

const URL = 'http://localhost:8080';

function WpAutomatePage() {
  const [phone, setPhone] = useState('');
  const [storeName, setStoreName] = useState('');
  const [qrImage, setQRImage] = useState('');
  const [multiplePhone, setMultiplePhone] = useState('');
  const [multipleMessage, setMultipleMessage] = useState('');

  const [message, setMessage] = useState('');
  const [contact, setContact] = useState('');
  const [contactName, setContactName] = useState('');
  const [imageCaption, setImageCaption] = useState('');

  const toastError = (error, message) => {
    if (error && error.response && error.response.data) {
      if (error.response.data.status) {
        toast.error(error.response.data.status);
      } else if (error.response.data.error) {
        toast.error(error.response.data.error);
      }
    } else {
      toast.error(message);
    }
  };

  const statusCheck = () => {
    Axios.get(`${URL}/`)
      .then((res) => toast.success(res.data.serverStatus))
      .catch((err) => toastError(err, 'Unable to check status'));
  };

  const initQR = () => {
    Axios.get(`${URL}/init`)
      .then((res) => toast.success(res.data.status))
      .catch((err) => toastError(err, 'Unable to init WP server'));
  };

  const resetQR = () => {
    Axios.get(`${URL}/reset`)
      .then((res) => toast.success(res.data.status))
      .catch((err) => toastError(err, 'Unable to reset WP server'));
  };

  const getWpStatus = () => {
    Axios.get(`${URL}/status`)
      .then((res) =>
        res.data.status
          ? toast.success(res.data.status)
          : toast.warning(
              'Init server and wait for 10 - 15 secs to initiate server'
            )
      )
      .catch((err) => toastError(err, 'Unable to get WP status'));
  };

  const getWpQR = () => {
    Axios.get(`${URL}/get-QR`)
      .then((res) => setQRImage(res.data))
      .catch((err) => toastError(err, 'Unable to get WP QR'));
  };

  const sendFirstMessage = () => {
    Axios.post(`${URL}/send-first-message`, {
      phone: phone,
      merchant_name: storeName,
    })
      .then(() => toast.success('Message sent'))
      .catch((err) => toastError(err, 'Unable to send'));
  };

  const sendSocialVoucher = () => {
    Axios.post(`${URL}/send-social-voucher`, {
      phone: phone,
      merchant_name: storeName,
    })
      .then(() => toast.success('Social Voucher sent'))
      .catch((err) => toastError(err, 'Unable to send'));
  };

  const sendDiscountVoucher = () => {
    Axios.post(`${URL}/send-discount-voucher`, {
      phone: phone,
      merchant_name: storeName,
    })
      .then(() => toast.success('Discount Voucher sent'))
      .catch((err) => toastError(err, 'Unable to send'));
  };

  const sendDineInToDelivery = () => {
    Axios.post(`${URL}/send-dine-in-to-delivery`, {
      phone: phone,
      merchant_name: storeName,
    })
      .then(() => toast.success('dine-in to delivery sent'))
      .catch((err) => toastError(err, 'Unable to send'));
  };

  const sendDeliveryToDineIn = () => {
    Axios.post(`${URL}/send-delivery-to-dine-in`, {
      phone: phone,
      merchant_name: storeName,
    })
      .then(() => toast.success('delivery to dine-in sent'))
      .catch((err) => toastError(err, 'Unable to send'));
  };

  const sendSwiggyZomato = () => {
    let message = `Thank you so much for visiting ${storeName} few days back. Just wanted to let you know that we're live on Zomato (https://zoma.to/order/18670863) and swiggy (https://www.swiggy.com/restaurants/ganga-sweets-navallur-navalur-chennai-8506)`;
    Axios.post(`${URL}/send-text`, {
      phone: phone,
      message: message,
    })
      .then(() => toast.success('Swiggy/Zomato links sent'))
      .catch((err) => toastError(err, 'Unable to send'));
  };

  const sendInstagramLink = () => {
    let link = `https://www.instagram.com/roasterycoffeehouseindia`;
    Axios.post(`${URL}/send-link`, {
      phone: phone,
      link: link,
      link_name :"Insta profile"
    })
      .then(() => toast.success('Instagram link sent'))
      .catch((err) => toastError(err, 'Unable to send'));
  };

  const sendText = () => {
    Axios.post(`${URL}/send-text`, {
      phone: phone,
      message: message,
    })
      .then(() => toast.success('text message sent'))
      .catch((err) => toastError(err, 'Unable to send'));
  };

  const sendMultipleText = () => {
    let numbers = multiplePhone.trim().split(',');
    if (numbers.length > 0) {
      for (let num of numbers) {
        Axios.post(`${URL}/send-text`, {
          phone: num.trim(),
          message: multipleMessage,
        })
          .then(() => toast.success('text message sent'))
          .catch((err) => toastError(err, 'Unable to send'));
      }
    }
  };

  const sendContact = () => {
    Axios.post(`${URL}/send-contact`, {
      phone: phone,
      number: contact,
      name: contactName,
    })
      .then(() => toast.success('contact sent'))
      .catch((err) => toastError(err, 'Unable to send'));
  };

  const sendImage = (image) => {
    const data = new FormData();
    data.append('file', image);
    data.append('phone', phone);
    data.append('captionText', imageCaption);
    Axios.post(`${URL}/send-image`, data)
      .then(() => toast.success('image sent'))
      .catch((err) => toastError(err, 'Unable to send'));
  };

  const uploadImage = async (file) => {
    await sendImage(file);
    document.getElementById('upload-image').value = '';
  };

  const deleteUploadImage = () => {
    document.getElementById('upload-image').value = '';
  };

  return (
    <>
      <Card bordered={false}>
        <Row gutter={[0, 16]}>
          <Col span={20}>
            <Title style={{marginBottom: 4}} level={3}>
              Whatsapp automation tool
            </Title>
          </Col>
          <Col span={4} style={{textAlign: 'right'}}>
            <Button type="primary" onClick={statusCheck}>
              Status
            </Button>
          </Col>
        </Row>
        <div style={{marginTop: 20}}>
          <Button type="primary" onClick={initQR}>
            Init wp server
          </Button>
          <Button
            style={{marginLeft: 16}}
            type="dashed"
            danger
            onClick={resetQR}
          >
            Reset wp server
          </Button>
        </div>
        <div style={{marginTop: 20}}>
          <Button type="primary" onClick={getWpStatus}>
            WP Status
          </Button>
        </div>
        <div style={{marginTop: 20, marginBottom: 20}}>
          <Button type="primary" onClick={getWpQR}>
            WP view QR
          </Button>
        </div>
        <Divider />
        <div style={{marginTop: 20}}>
          <label>Enter Whatsapp phone number </label>
          <Input
            placeholder="eg: 911234567890"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <label>Enter Store Name </label>
          <Input
            placeholder="eg: Digital store"
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
          />
        </div>
        <div style={{marginTop: 20}}>
          <Row gutter={12}>
            <Col style={{marginBottom: 12}}>
              <Button
                disabled={!phone || !storeName}
                onClick={sendFirstMessage}
              >
                Send First message
              </Button>
            </Col>
            <Col style={{marginBottom: 12}}>
              <Button
                disabled={!phone || !storeName}
                onClick={sendSocialVoucher}
              >
                Send Social Voucher
              </Button>
            </Col>
            <Col style={{marginBottom: 12}}>
              <Button
                disabled={!phone || !storeName}
                onClick={sendDiscountVoucher}
              >
                Send Discount Voucher
              </Button>
            </Col>
            <Col style={{marginBottom: 12}}>
              <Button
                disabled={!phone || !storeName}
                onClick={sendDineInToDelivery}
              >
                Send Dine-in to delivery
              </Button>
            </Col>
            <Col style={{marginBottom: 12}}>
              <Button
                disabled={!phone || !storeName}
                onClick={sendDeliveryToDineIn}
              >
                Send Delivery to dine-in
              </Button>
            </Col>
            <Col style={{marginBottom: 12}}>
              <Button
                disabled={!phone || !storeName}
                onClick={sendSwiggyZomato}
              >
                Send Swiggy/Zomato links
              </Button>
            </Col>
            <Col style={{marginBottom: 12}}>
              <Button
                disabled={!phone || !storeName}
                onClick={sendInstagramLink}
              >
                Send Instagram link
              </Button>
            </Col>
          </Row>
        </div>
        <Divider />
        <div style={{marginTop: 20}}>
          <Row gutter={12}>
            <Col style={{marginBottom: 12}}>
              <label>Enter Message</label>
              <Input
                placeholder="eg: any text message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col style={{marginBottom: 12}}>
              <Button disabled={!phone || !message} onClick={sendText}>
                Send text message
              </Button>
            </Col>
          </Row>
        </div>
        <Divider />
        <div style={{marginTop: 20}}>
          <Row gutter={12}>
            <Col style={{marginBottom: 12}}>
              <label>Enter Contact number</label>
              <Input
                placeholder="eg: 919876543210"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
              />
            </Col>
            <Col style={{marginBottom: 12}}>
              <label>Enter Contact Name</label>
              <Input
                placeholder="eg: name"
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col style={{marginBottom: 12}}>
              <Button
                disabled={!phone || !contact || !contactName}
                onClick={sendContact}
              >
                Send contact message
              </Button>
            </Col>
          </Row>
        </div>
        <Divider />
        <div style={{marginTop: 20}}>
          <Row gutter={12} style={{alignItems: 'flex-end'}}>
            <Col style={{marginBottom: 12}}>
              <label>{'Enter Image caption (optional)'}</label>
              <Input
                placeholder="eg: any text"
                value={imageCaption}
                onChange={(e) => setImageCaption(e.target.value)}
              />
            </Col>
            <Col style={{marginBottom: 12}}>
              <Button disabled={!phone} icon={<UploadOutlined />}>
                Send Image
                <input
                  style={{
                    zIndex: 1000,
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    opacity: 0,
                    left: 0,
                    cursor: 'pointer',
                  }}
                  id="upload-image"
                  type="file"
                  multiple={false}
                  accept=".png, .jpeg, .jpg"
                  onChange={(e) => {
                    uploadImage(e.target.files[0]);
                  }}
                  onClick={() => {
                    deleteUploadImage();
                  }}
                />
              </Button>
            </Col>
          </Row>
        </div>
        <Divider />
        <div style={{marginTop: 12}}>
          <Row gutter={12}>
            <Col style={{marginBottom: 12}}>
              <label>Enter Whatsapp phone numbers </label>
              <Input
                placeholder="eg: 911234567890, 919876543210 ,..."
                value={multiplePhone}
                onChange={(e) => setMultiplePhone(e.target.value)}
              />
            </Col>
          </Row>
          <Row gutter={12} style={{alignItems: 'flex-end'}}>
            <Col style={{marginBottom: 12}}>
              <label>Enter Message</label>
              <TextArea
                placeholder="eg: any text message"
                value={multipleMessage}
                onChange={(e) => setMultipleMessage(e.target.value)}
              />
            </Col>
            <Col style={{marginBottom: 12}}>
              <Button
                disabled={!multiplePhone || !multipleMessage}
                onClick={sendMultipleText}
              >
                Send text message
              </Button>
            </Col>
          </Row>
        </div>
      </Card>
      <Modal
        title="Scan the QR"
        bodyStyle={{textAlign: 'center'}}
        visible={Boolean(qrImage)}
        onOk={() => setQRImage('')}
        onCancel={() => setQRImage('')}
      >
        <img src={qrImage} alt="" height={250} width={250} />
      </Modal>
    </>
  );
}

export default WpAutomatePage;
