import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useSelector} from 'react-redux';

function PayoutAuthHoc({component: Component, ...rest}) {
  const userData = useSelector((state) => state.auth.auth);
  const token = userData.token ? userData.token : '';
  const isPayout = useSelector(
    (state) =>
      state.auth.userData &&
      state.auth.userData.role &&
      state.auth.userData.role.includes('payout')
  );
  return (
    <Route
      {...rest}
      render={(prop) =>
        token !== '' && isPayout ? (
          <Component {...prop} />
        ) : (
          <Redirect to={'/home'} />
        )
      }
    />
  );
}

export default PayoutAuthHoc;
