const initialState = {
  store: {},
  categories: [],
  items: [],
  preferences: {},
  taxes: [],
  noLoyalty: false,
  loyalty: {},
  voucher: {
    result: [],
    __metadata: {},
  },
  urbanPiper: {},
  translations: [],
  userLoyalty: [],
  gstIN: [],
};

export default function salesReportReducer(state = initialState, action) {
  switch (action.type) {
    case 'INIT_STORE':
      return {
        ...state,
        store: action.payload
      }
    case 'INIT_CATEGORIES':
      return {
        ...state,
        categories: action.payload
      }
    case 'INIT_ITEMS':
      return {
        ...state,
        items: action.payload
      }
    case 'INIT_PREFERENCES':
      return {
        ...state,
        preferences: action.payload,
      };
    case 'INIT_TAX':
      return {
        ...state,
        taxes: action.payload,
      };
    case 'INIT_STORE_GSTIN':
      return {
        ...state,
        gstIN: action.payload,
      }
    case 'ADD_TAX':
      const add_taxes = [...state.taxes];
      add_taxes.push(action.payload);
      return {
        ...state,
        taxes: add_taxes,
      };
    case 'UPDATE_TAX':
      const update_taxes = [...state.taxes];
      const update_taxes_index = update_taxes.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_taxes_index > -1) {
        update_taxes[update_taxes_index] = action.payload;
      }
      return {
        ...state,
        taxes: update_taxes,
      };
    case 'DELETE_TAX':
      const delete_taxes = [...state.taxes];
      const delete_taxes_index = delete_taxes.findIndex(
        (a) => a._id === action.payload._id
      );
      if (delete_taxes_index > -1) {
        delete_taxes.splice(delete_taxes_index, 1);
      }
      return {
        ...state,
        taxes: delete_taxes,
      };
    
    case 'INIT_VOUCHERS':
      return {
        ...state,
        voucher: action.payload,
      };
    case 'ADD_VOUCHER':
      const add_voucher = {...state.voucher};
      add_voucher.result.push(action.payload);
      return {
        ...state,
        voucher: add_voucher,
      };
    case 'UPDATE_VOUCHER':
      const update_voucher = {...state.voucher};
      const update_voucher_index = update_voucher.result.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_voucher_index > -1) {
        update_voucher.result[update_voucher_index] = action.payload;
      }
      return {
        ...state,
        voucher: update_voucher,
      };
    case 'DELETE_VOUCHER':
      const delete_voucher = {...state.voucher};
      const delete_voucher_index = delete_voucher.result.findIndex(
        (a) => a._id === action.payload._id
      );
      if (delete_voucher_index > -1) {
        delete_voucher.result.splice(delete_voucher_index, 1);
      }
      return {
        ...state,
        voucher: delete_voucher,
      };


      
    case 'USER_LOYALTY':
      return {
        ...state,
        userLoyalty: action.payload,
      };
    case 'INIT_LOYALTY':
      return {
        ...state,
        noLoyalty: false,
        loyalty: action.payload,
      };
    case 'NO_LOYALTY':
      return {
        ...state,
        noLoyalty: true,
      };
    case 'ADD_LOYALTY':
      let add_loyalty = {};
      if (Object.keys(state.loyalty).length > 0) {
        add_loyalty = {...state.loyalty};
        add_loyalty['discounts'].push(action.payload);
      } else {
        add_loyalty = {...action.payload};
      }
      return {
        ...state,
        noLoyalty: false,
        loyalty: add_loyalty,
      };
    case 'UPDATE_LOYALTY':
      return {
        ...state,
        noLoyalty: false,
        loyalty: action.payload,
      };
    case 'DELETE_LOYALTY':
      return {
        ...state,
        noLoyalty: true,
        loyalty: {},
      };
    case 'FETCH_URBAN_PIPER':
      return {
        ...state,
        urbanPiper: action.payload,
      };
    case 'SET_TRANSLATIONS':
      return {
        ...state,
        translations: action.payload,
      };
    case 'RESET_PREFERENCES':
      return {
        store: {},
        categories: [],
        items: [],
        preferences: {},
        taxes: [],
        noLoyalty: false,
        loyalty: {},
        voucher: {
          result: [],
          __metadata: {},
        },
        urbanPiper: {},
        translations: [],
        userLoyalty: [],
      }
    default:
      return state;
  }
}
