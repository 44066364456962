
const initialState = {
  loading: false,
  list: [],
};

export default function subscriptionReducers(state = initialState, action) {
  switch (action.type) {
    case 'SUBSCRIPTION_LOADING':
      return {
        ...state,
        loading: action.payload,
      }
    case 'INIT_SUBSCRIPTION':
      return {
        ...state,
        list: action.payload,
      }
    case 'ADD_TO_SUBSCRIPTION':
      return {
        ...state,
        list: [action.payload, ...state.list],
      }
    default:
      return state;
  }
}
