import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import authReducers from './authReducers';
import employeeReducers from './employeeReducers';
import miscReducers from './miscReducers';
import paymentReducers from './paymentsReducers';
import deliveryReducers from './deliveryReducers';
import statsReducers from './statsReducers';
import preferencesReducers from './preferencesReduces';
import bannerReducers from './bannerReducers';
import vouchersReducer from './vouchersReducer';
import thirdPartyReducers from './thirdPartyReducers';
import jukeboxReducers from './jukeboxReducers';
import riderReducers from './riderReducers';
import feedbackReducers from './feedbackReducers';
import contactsReducers from './contactsReducers';
import subscriptionReducers from './subscriptionReducers';
import blogsReducer from './blogsReducers';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducers,
    employees: employeeReducers,
    misc: miscReducers,
    payout: paymentReducers,
    delivery: deliveryReducers,
    preferences: preferencesReducers,
    banners: bannerReducers,
    stats: statsReducers,
    vouchers: vouchersReducer,
    thirdParty: thirdPartyReducers,
    jukebox: jukeboxReducers,
    rider: riderReducers,
    feedback: feedbackReducers,
    contacts: contactsReducers,
    subscription: subscriptionReducers,
    blogs: blogsReducer,
  });

const rootReducer = (history) => (state, action) => {
  if (action.type === 'RESET') {
    state = undefined;
  }

  return reducers(history)(state, action);
};

export default rootReducer;
