import * as actionTypes from '../../actionTypes';

const initialState = {
  thirdPartyUsers: [],
  thirdPartyLoading: false,
};

export default function thirdPartyReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_THIRD_PARTY_USERS:
      return {
        ...state,
        thirdPartyUsers: action.payload,
      };
    case actionTypes.THIRD_PARTY_LOADING:
      return {
        ...state,
        thirdPartyLoading: action.payload
      }
    default:
      return state;
  }
}
