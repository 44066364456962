import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {http} from '../../http';

export const fetchStats = (from, to, store_ids) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STATS_DATA_LOADING});
    let url = `${API_URL}/dashboard/summary?from=${from}&to=${to}`
    if(store_ids){
      url = `${API_URL}/dashboard/summary?from=${from}&to=${to}&store_ids=${store_ids}`
    }
    http
      .get(url)
      .then(async (res) => {
        dispatch({type: actionTypes.STATS_DATA_SUCCESS, payload: res.data});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STATS_DATA_FAILED, payload: err});
      });
  };
};

export const fetchOrders = (from, to, settled, active) => {
  return (dispatch) => {
    dispatch({type: actionTypes.ORDERS_DATA_LOADING});
    http
      .get(
        `${API_URL}/dashboard/orders?limit=1000&page=1&from=${from}&to=${to}&settled=${settled}&active=${active}`
      )
      .then(async (res) => {
        dispatch({type: actionTypes.ORDERS_DATA_SUCCESS, payload: res.data});
      })
      .catch((err) => {
        dispatch({type: actionTypes.ORDERS_DATA_FAILED, payload: err});
      });
  };
};

export const fetchRestaurants = () => {
  return (dispatch) => {
    dispatch({type: actionTypes.REST_DATA_LOADING});
    http
      .get(`${API_URL}/stores?limit=10000&page=1`)
      .then(async (res) => {
        dispatch({type: actionTypes.REST_DATA_SUCCESS, payload: res.data});
      })
      .catch((err) => {
        dispatch({type: actionTypes.REST_DATA_FAILED, payload: err});
      });
  };
};


export const getStoresWalletMessageTransaction = () => {
  return (dispatch) => {
    dispatch({type: 'LOADING', payload: true});
    http
      .get(
        `${API_URL}/dashboard/store/details`
      )
      .then((res) => {
        dispatch({type: 'LOADING', payload: false});
        dispatch({type: 'SET_WALLET_MESSAGE_TRANSACTIONS', payload: res.data});
      })
      .catch(() => {
        dispatch({type: 'LOADING', payload: false});
      });
  }
}

export const getOrderPayments = (from, to, mode) => {
  return (dispatch) => {
    dispatch({type: 'LOADING', payload: true});
    let url = `${API_URL}/orders/admin/details?from=${from}&to=${to}`
    if(mode) {
      url += `&mode=${mode}`
    }
    http
      .get(url)
      .then((res) => {
        dispatch({type: 'LOADING', payload: false});
        dispatch({type: 'SET_ORDER_PAYMENT_DETAILS', payload: res.data});
      })
      .catch(() => {
        dispatch({type: 'LOADING', payload: false});
      });
  }
}

export const getAllRafflesList = () => {
  return (dispatch) => {
    dispatch({type: 'LOADING', payload: true});
    http
      .get(`${API_URL}/ita/raffle`)
      .then((res) => {
        dispatch({type: 'LOADING', payload: false});
        dispatch({type: 'SET_RAFFLES_DETAILS', payload: res.data});
      })
      .catch(() => {
        dispatch({type: 'LOADING', payload: false});
      });
  }
}

export const setRafflesWinner = (raffle_id, user_id) => {
  return (dispatch) => {
    dispatch({type: 'LOADING', payload: true});
    http
      .post(`${API_URL}/ita/raffle/${raffle_id}/${user_id}/reward`)
      .then((res) => {
        dispatch({type: 'LOADING', payload: false});
        dispatch(getAllRafflesList());
      })
      .catch(() => {
        dispatch({type: 'LOADING', payload: false});
      });
  }
}