import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'antd';
import moment from 'moment';
import { getAllRafflesList, setRafflesWinner } from '../../services/actions/statsActions';
import { http } from '../../services/http';
import { API_URL } from '../../utils/constants';

const ExpandUI = (props) => {
  const {record} = props;
  const dispatch = useDispatch();
  const [participants, setParticipants] = useState([]);
  useEffect(() => {
    if(record._id){
      http.get(`${API_URL}/ita/raffle/${record._id}/participants`)
      .then(res => {
        if(res.data){
          setParticipants(res.data);
        }
      })
      .catch(err => {
        console.log("err", err)
      })
    }
  },[record])

  const handleClick = (user_id) => {
    dispatch(setRafflesWinner(record._id, user_id))
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: ['user_id','fName'],
      key: 'user_id',
    },
    {
      title: 'Phone',
      dataIndex: ['user_id','phone'],
      key: 'phone',
    },
    {
      title: 'User from',
      dataIndex: ['user_id','createdAt'],
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('DD MMM YYYY')
    },
    {
      title: 'Tickets',
      dataIndex: ['tickets'],
      key: 'tickets',
    },
    {
      title: 'Action',
      key: 'actions',
      render: (data) => data.rewarded ? 
        data.winner === data.user_id._id ? 'Winner' : '' :
        <Button onClick={() => handleClick(data.user_id._id)}>
          Mark as Winner
        </Button>
    },
  ]

  return (
    <Table
       columns={columns}
       dataSource={participants}
       style={{width: '100%'}}
       pagination={{ pageSize: 10 }}
    />
  )
}

function RafflesPage(){
  const dispatch = useDispatch();
  const rafflesList = useSelector((state) => state.stats.raffles);

  useEffect(() => {
    dispatch(getAllRafflesList())
  }, [dispatch])


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },{
      title: 'Reward',
      dataIndex: 'reward',
      key: 'reward',
      render: (reward) => `₹ ${reward}`
    },{
      title: 'Event Date',
      key: 'from-to',
      render: (data) => `${moment(data.from).format('DD MMM YYYY')} - ${moment(data.to).format('DD MMM YYYY')}`
    },{
      title: 'Result Date',
      dataIndex: 'trigger',
      key: 'trigger',
      render: (data) => `${moment(data).format('DD MMM YYYY')}`
    },
  ]

  return (
    <div>
      <h3>
        {"Raffles (ITA)"}
      </h3>
      <Table
       columns={columns}
       dataSource={rafflesList}
       style={{width: '100%'}}
       pagination={{ pageSize: 15 }}
       rowKey={'name'}
       expandable={{
        expandedRowRender: record => <ExpandUI record={record} />,
      }}
      />
    </div>

  )
}

export default RafflesPage;