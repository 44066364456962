import React, {lazy, useState} from 'react';
import {Divider, Layout, Menu} from 'antd';
import {
  BankOutlined,
  HomeOutlined,
  LogoutOutlined,
  WalletOutlined,
  ShoppingOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
  ProjectOutlined,
  PlayCircleOutlined,
  ContactsOutlined,
  GiftOutlined,
  CarOutlined,
  BookOutlined,
} from '@ant-design/icons';
import './App.less';
import {useLocation} from 'react-router-dom';

import {Switch} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import Text from 'antd/es/typography/Text';
import PrivateAuthHoc from './hoc/PrivateAuthHoc';
import suspenseHoc from './hoc/suspenseHoc';
import LandingAuthHoc from './hoc/LandingAuthHoc';
import AdminAuthHoc from './hoc/AdminAuthHoc';
import PayoutAuthHoc from './hoc/PayoutAuthHoc';
import DeliveryAuthHoc from './hoc/DeliveryAuthHoc';
import OrderAuthHoc from './hoc/OrderAuthHoc';
import WpAutomatePage from './pages/WpAutomatePage';
import RafflesPage from './pages/RafflesPage';
import { ToastContainer } from 'react-toastify';

const {SubMenu} = Menu;
const {Header, Content, Sider} = Layout;

const OverviewPage = lazy(() => import('./pages/OverviewPage'));
const AuthPage = lazy(() => import('./pages/AuthPage'));
const LiveOrdersPage = lazy(() => import('./pages/LiveOrdersPage'));
const PreferencesPage = lazy(() => import('./pages/PreferencesPage'));
const BannersPage = lazy(() => import('./pages/BannersPage'));
const ManageEmployeesPage = lazy(() => import('./pages/ManageEmployeesPage'));
const DeliveryManagementPage = lazy(() =>
  import('./pages/DeliveryManagementPage')
);
const PayoutPage = lazy(() => import('./pages/PayoutPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const Vouchers = lazy(() => import('./pages/Vouchers'));
const OnlinePaymentPage = lazy(() => import('./pages/OnlinePaymentPage'));
const UsersPage = lazy(() => import('./pages/UsersPage'));
const Tables = lazy(() => import('./pages/TablesPage'));
const WalletPage = lazy(() => import('./pages/WalletPage'));
const ThirdPartyAnalytics = lazy(() => import('./pages/ThirdPartyAnalytics'));
const JukeBoxPage = lazy(() => import('./pages/JukeBoxPage'));
const FeedbackPage = lazy(() => import('./pages/FeedbackPage'));
// const RiderPage = lazy(() => import('./pages/RiderPage'));
const ContactsPage = lazy(() => import('./pages/ContactsPage'));
const BountyPage = lazy(() => import('./pages/BountyPage'));
const SubscriptionPage = lazy(() => import('./pages/SubscriptionPage'));
const CityPage = lazy(() => import('./pages/CityPage'));
const ITAAppPage = lazy(() => import('./pages/ITAAppPage'));
const ManageBlogsPage = lazy(() => import('./pages/ManageBlogsPage'));

const App = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const [collapse, setCollapse] = useState(false);

  const isLoggedIn = !!useSelector(
    (state) => state.auth.auth && state.auth.auth.token
  );

  const routeMatch = [
    '/overview',
    '/live-orders',
    '/preferences',
    '/banners',
    '/vouchers',
    '/online-payment',
    '/users',
    '/tables',
    '/bounty',
    '/payouts',
    '/manage-employees',
    '/delivery',
    '/wallet',
    '/third-party-analytics',
    '/jukebox',
    '/feedback',
    // '/rider',
    '/contacts',
    '/subscription',
    '/raffles',
    '/cities',
    '/ita-app',
    '/manage-blogs'
  ];

  const roles = useSelector(
    (state) => state.auth.userData && state.auth.userData.role
  );

  const handleClick = (e) => {
    dispatch(push(routeMatch[parseInt(e.key) - 1]));
  };

  return (
    <>
      <Layout>
        <Header
          className="header"
          style={{position: 'sticky', top: 0, zIndex: 2, display: 'flex'}}
        >
          <div className="logo" />
          <Text
            level={3}
            style={{
              color: '#FFFFFF',
              fontSize: 18,
              cursor: 'pointer',
              userSelect: 'none',
              flexGrow: 1,
            }}
            onClick={() => dispatch(push('/'))}
          >
            WTF Admin Dashboard
          </Text>
        </Header>
        <Layout>
          <Sider
            width={isLoggedIn ? 250 : 0}
            style={{
              overflow: 'auto',
              height: 'calc(100vh - 112px)',
              position: 'fixed',
              left: 0,
              display: isLoggedIn ? 'block' : 'none',
            }}
            collapsible
            collapsed={collapse}
            onCollapse={() => setCollapse(!collapse)}
            className="site-layout-background"
          >
            <Menu
              mode="inline"
              onClick={handleClick}
              theme="dark"
              inlineCollapsed={collapse}
              selectedKeys={[
                (routeMatch.indexOf(location.pathname) + 1).toString(),
              ]}
              defaultSelectedKeys={[
                (routeMatch.indexOf(location.pathname) + 1).toString(),
              ]}
              // defaultOpenKeys={['home-submenu']}
              style={{borderRight: 0}}
            >
              {roles && roles.includes('payout') ? (
                <Menu.Item icon={<BankOutlined />} key="10">
                  Payout
                </Menu.Item>
              ) : (
                <></>
              )}
              {roles &&
              (roles.includes('delivery') ||
                roles.includes('admin') ||
                roles.includes('marketmyindia') ||
                roles.includes('jumelia')) ? (
                <Menu.Item icon={<ShoppingCartOutlined />} key="2">
                  Live Orders
                </Menu.Item>
              ) : (
                <></>
              )}
              {roles &&
              (roles.includes('delivery') || roles.includes('admin')) ? (
                <Menu.Item icon={<ShoppingOutlined />} key="12">
                  Delivery Status
                </Menu.Item>
              ) : (
                <></>
              )}
              {/* {roles && roles.includes('admin') ? (
                <Menu.Item icon={<RocketOutlined />} key="13">
                  Rider
                </Menu.Item>
              ) : (
                <></>
              )} */}
              {roles && roles.includes('admin') ? (
                <>
                  <SubMenu
                    key="home-submenu"
                    icon={<HomeOutlined />}
                    title="Home"
                  >
                    <Menu.Item key="1">Overview</Menu.Item>
                    {/* <Menu.Item key="2">Live Orders</Menu.Item> */}
                    <Menu.Item key="3">Preferences</Menu.Item>
                    <Menu.Item key="4">Banners</Menu.Item>
                    <Menu.Item key="5">Vouchers</Menu.Item>
                    <Menu.Item key="6">Online payment</Menu.Item>
                    <Menu.Item key="7">User</Menu.Item>
                    <Menu.Item key="8">Tables</Menu.Item>
                    <Menu.Item key="9">Bounty</Menu.Item>
                  </SubMenu>
                  <Menu.Item icon={<UserOutlined />} key="11">
                    Manage Employees
                  </Menu.Item>
                  <Menu.Item icon={<WalletOutlined />} key="13">
                    Stores wallet
                  </Menu.Item>
                  <Menu.Item icon={<ProjectOutlined />} key="14">
                    Third Party Analytics
                  </Menu.Item>
                  <Menu.Item icon={<PlayCircleOutlined />} key="15">
                    JukeBox
                  </Menu.Item>
                  <Menu.Item icon={<SolutionOutlined />} key="16">
                    Feedback
                  </Menu.Item>
                  <Menu.Item icon={<ContactsOutlined />} key="17">
                    Contacts
                  </Menu.Item>
                  <Menu.Item icon={<BankOutlined />} key="18">
                    Subscription
                  </Menu.Item>
                  <Menu.Item icon={<GiftOutlined />} key="19">
                    Raffles
                  </Menu.Item>
                  <Menu.Item icon={<CarOutlined />} key="20">
                    Cities
                  </Menu.Item>
                  <Menu.Item icon={<WalletOutlined />} key="21">
                    ITA app
                  </Menu.Item>
                  <Menu.Item icon={<BookOutlined />} key="22">
                    Blogs
                  </Menu.Item>
                </>
              ) : (
                <></>
              )}
              <Divider />
              <Menu.Item
                onClick={() =>
                  dispatch({
                    type: 'RESET',
                  })
                }
                danger
                icon={<LogoutOutlined />}
              >
                Logout
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Content
              className="site-layout-background"
              style={{
                margin: 0,
                minHeight: 'calc(100vh - 112px)',
                marginLeft: isLoggedIn ? (collapse ? 92 : 262) : 0,
                marginTop: 12,
                marginRight: 12,
                marginBottom: 12,
              }}
            >
              <Switch>
                <LandingAuthHoc
                  exact
                  path="/"
                  component={suspenseHoc(AuthPage)}
                />
                <PrivateAuthHoc
                  exact
                  path="/home"
                  component={suspenseHoc(HomePage)}
                />
                <AdminAuthHoc
                  exact
                  path="/overview"
                  component={suspenseHoc(OverviewPage)}
                />
                <OrderAuthHoc
                  exact
                  path="/live-orders"
                  component={suspenseHoc(LiveOrdersPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/vouchers"
                  component={suspenseHoc(Vouchers)}
                />
                <AdminAuthHoc
                  exact
                  path="/tables"
                  component={suspenseHoc(Tables)}
                />
                <AdminAuthHoc
                  exact
                  path="/wallet"
                  component={suspenseHoc(WalletPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/preferences"
                  component={suspenseHoc(PreferencesPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/banners"
                  component={suspenseHoc(BannersPage)}
                />
                <PayoutAuthHoc
                  exact
                  path="/payouts"
                  component={suspenseHoc(PayoutPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/manage-employees"
                  component={suspenseHoc(ManageEmployeesPage)}
                />
                <DeliveryAuthHoc
                  exact
                  path="/delivery"
                  component={suspenseHoc(DeliveryManagementPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/third-party-analytics"
                  component={suspenseHoc(ThirdPartyAnalytics)}
                />
                <AdminAuthHoc
                  exact
                  path="/jukebox"
                  component={suspenseHoc(JukeBoxPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/online-payment"
                  component={suspenseHoc(OnlinePaymentPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/users"
                  component={suspenseHoc(UsersPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/bounty"
                  component={suspenseHoc(BountyPage)}
                />
                {/* <AdminAuthHoc
                  exact
                  path="/rider"
                  component={suspenseHoc(RiderPage)}
                /> */}
                <AdminAuthHoc
                  exact
                  path="/contacts"
                  component={suspenseHoc(ContactsPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/feedback"
                  component={suspenseHoc(FeedbackPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/subscription"
                  component={suspenseHoc(SubscriptionPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/raffles"
                  component={suspenseHoc(RafflesPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/cities"
                  component={suspenseHoc(CityPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/ita-app"
                  component={suspenseHoc(ITAAppPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/wp-automate"
                  component={suspenseHoc(WpAutomatePage)}
                />
                <AdminAuthHoc
                  exact
                  path="/manage-blogs"
                  component={suspenseHoc(ManageBlogsPage)}
                />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable
        pauseOnHover={false}
      />
    </>
  );
};

export default App;
