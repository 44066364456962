import * as actionTypes from '../../actionTypes';

const initialState = {
  songs: [],
  loading: false,
};

export default function jukeboxReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.JUKEBOX_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.INIT_JUKEBOX_SONGS:
      return {
        ...state,
        songs: action.payload,
      };
    default:
      return state;
  }
}
