import * as actionTypes from '../../actionTypes';

const initialState = {
  loading: false,
  cities: [],
};

export default function miscReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.SET_CITIES_DATA:
      return {
        ...state,
        cities: action.payload,
      };
    case actionTypes.UPDATE_CITY_DATA: {
      let temp = [...state.cities];
      const index = temp.findIndex((a) => a._id === action.payload._id);
      if (index > -1) {
        temp[index] = action.payload;
      }
      return {
        ...state,
        cities: temp,
      };
    }
    default:
      return state;
  }
}
